import React from 'react';
import { Link } from 'react-router-dom';
import {CONF_VAL} from '../../config/constant';
//import logo_inspection from '../../webroot/images/oto-logo-2x.png';
//import logo_carmaudi from '../../webroot/images/carmudi-logo.png';

class Brandlogo extends React.Component {    
    render() {
    return (
        <Link className="navbar-brand" to="/"><img src={CONF_VAL.logo} alt="" /></Link>
               
        );
    }
}
export default Brandlogo;