'use strict'

import React from 'react';
class Button extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.id || "",
			type: props.type || "",
			colclass: props.colclass || "",


		};
	}

	render() {
		const { showmodal, id, type } = this.state;
		var { title, children, colclass } = this.props;
		return (
			<button
				onClick={this.props.onClick}
				disabled={this.props.disabled}
				type={type}
				id={id}
				className={this.props.btnClass}
			>{this.props.title}
				{children}
			</button>

			// <div className={{this.props.title}} 
		);
	}
}


export default Button;
