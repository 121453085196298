import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import InputField from '../elements/InputField';
import Select from 'react-select';

const searchtype = [
  { value: '0', label: 'Page 1' },
  { value: '1', label: 'Page 2' },
  { value: '2', label: 'RC Copy' },
  { value: '3', label: 'Aadhar' },
  { value: '4', label: 'Passport' },

];
const city =[
  {value:"New Delhi", label:"New Delhi"},
  {value:"Gurgaon", label:"Gurgaon"},
  {value:"Faridabad", label:"Faridabad"}
];

class SearchCar extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChangetext = this.handleChangetext.bind(this);
    this.state = {
      key: 1,
      searchtype:"",
      date_type:"",
      searchbar:false,
    };
    this.showsearch = this.showsearch.bind(this);
  }
  
  showsearch() {
    this.setState({searchbar: !this.state.searchbar})
}
  handleSelect(key) {
    this.setState({ key });
  }

  handleChange = (sname, ovalue) => {
    this.setState({
      [sname]: ovalue.value
    });
    
  }

  handleChangetext = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    return (
        <div className="searchbox">
          <div className="searchitem" onClick={this.showsearch} >{ this.state.searchbar ? <span><i className="ic-clearclose icons-normal" ></i>Close </span> : <span><i className='ic-search icons-normal'></i> Search</span> }</div>
          
          <div  className= { this.state.searchbar ? "searchbar open": "searchbar" }>
          <div className="row">
            <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-6 form-group" >
                    <label>Search By</label>
                    <Select
                    id="search_type"
                    value={this.state.searchtype}
                    onChange={this.handleChange.bind(this, 'searchtype')}
                    options={searchtype}
                    name="search_type"
                    placeholder="Search By"
                    value={searchtype.filter(({ value }) => value === this.state.searchtype)}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    />           
                  </div>
                  <div className="col-sm-6">
                  <InputField
                  inputProps={{
                      id: "reg_no",
                      type: "text",
                      placeholder:"",
                      name: "reg_no",
                      autocompleate: "off",
                      label:"Enter ID",
                      value: '',
                      // disabled: carRegisterEnableStatus,
                      maxLength:10
                  }}
                  onChange={this.handleChangetext}
                />
                  </div>                  
                </div>                
              </div>

              <div className="col-sm-3 form-group" >
                <label>Search By City</label>
                <Select
                  id="city"
                  value={this.state.city}
                  onChange={this.handleChange.bind(this, 'city')}
                  options={city}
                  name="city"
                  placeholder="City"
                  value={city.filter(({ value }) => value === this.state.city)}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  />      
              </div>
              <div className="col-sm-3 form-group" >
                <label> </label>
                <div><button type="submit" name="search" className="btn btn-primary">Search</button></div>
              </div>
            </div>
            </div>
             
        </div>
    );
  }
}


export default SearchCar;