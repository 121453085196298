import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import secureStorage from './../../../config/encrypt';
import OtpValidation from './otp-validate';
import { withTranslation } from 'react-i18next';
import MasterService from './../../../service/MasterService';
import { toast } from 'react-toastify';
class OtpLogin extends Component {
    constructor(props){
        super(props);
        this.state = {
            showotpFrm:false,
            errors:{},
            fields:{},
            redirect: 0,
        }
    }

    handleChange = event => {
        let fields = {...this.state.fields};
        const errors = {...this.state.errors};
        fields[event.target.name] = event.target.value;
        if(event.target.value !== ''){
            delete errors[event.target.name];
        }else{
            errors[event.target.name] = 'Please enter '+event.target.name;
        }
        this.setState({ fields: fields, errors: errors });
    }
    
    submitOtpForm = (event)=>{
        var parentObj = this;
        event.preventDefault();
        if(this.validForm()){
            MasterService.post('account/user/send_otp', this.state.fields)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    let responseData = response.data;
                    parentObj.setState({showotpFrm:true});
                }else{
                    window.commonErrorToast(response.data.message);
                }
            }).catch(function (error) {
                window.commonErrorToast(error.message);
            });
        }
    }

    validForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!fields["mobile"]) {
            errors['mobile'] = "Please enter mobile";
            formIsValid = false;
        }else if(!fields['mobile'].match('[8][0-9]{7,11}')){
            errors['mobile'] = "Please enter valid mobile starting with 8";
            formIsValid = false;
        }
        this.setState({errors: errors})
        return formIsValid;
    }

    validateOtpAndLogin = (event)=>{
        var parentObj = this;
        event.preventDefault();
        if(this.validOtpForm()){
            MasterService.post('account/user/login-otp', this.state.fields)
            .then(function (response) {
                if(response.status == 200 && response.data.status == 200){
                    let responseData = response.data;
                    secureStorage.setItem('loginUserInfo', responseData);
                    parentObj.props.updateStateIntoRedux('LOGIN_USER', responseData);
                    parentObj.setState({redirect: 1 })
                }
            }).catch(function (error) {
                console.log(error.message);
            });
        }
    }

    validOtpForm = ()=>{
        let fields = {...this.state.fields};
        let errors = {...this.state.errors};
        let formIsValid = true;
        if (!fields["otp"]) {
            errors['otp'] = "Please enter otp";
            formIsValid = false;
        }
        this.setState({errors: errors})
        return formIsValid;
    }

    render() {
        if (this.state.redirect == 1) {
            return (<Redirect to={'/dashboard'} />);
        }
        const {errors} = this.state;
        return (
            <div id="otp-forms">
                {this.state.showotpFrm === false?
                <form method="post" id="otp-form1" className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                    <InputField
                        inputProps={{
                            id: "mobile",
                            type: "tel",
                            name: "mobile",
                            label:this.props.t('login.mobile'),
                            dataerror: errors.mobile,
                            maxLength: 12
                        }}
                        onChange={this.handleChange}
                    />
                
                    <div className="form-group" id="showbutton">
                        <button type="submit" className="btn btn-primary btn-lg btn-block" id="send-otp" onClick={this.submitOtpForm}>{this.props.t('login.get_otp')}</button>
                    </div>
                </form>
                :
                <OtpValidation errors={this.state.errors} onClick={this.validateOtpAndLogin} onChange={this.handleChange} />
                }
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
 }

 const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({type:actionType, componentState: stateData})
    }
 }
export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)( OtpLogin));
