import React, { Component } from 'react';
import './webroot/css/main.css';
import InternationalRoute from './routes/route';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <ToastContainer position={toast.POSITION.TOP_RIGHT} pauseOnFocusLoss ={false}/>
        <InternationalRoute />
      </div>
    );
  }
}

export default App;
