import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Header from './../view/common/Header';
import Footer from './../view/common/Footer';
import Login from './../view/login/component/login';
import QualityControl from './../view/quality-control/QualityControl';
import Dashboard from '../view/quality-control/Dashboard';
import Report from '../view/quality-control/Report';
import Logout from './../view/common/logout';
//Custom component
import PrivateRoute from './privateRoute';
import MobileBlock from '../view/quality-control/MobileBlock';
import AddMobileBlock from '../view/quality-control/AddMobileBlock';

// import BuyerLead from '../view/enquiry/component/BuyerEnquiry';
// import AdminRoute from './routeAdmin'
// import ForgotPassword from './../view/login/component/ForgotPassword';
// import ResetPassword from './../view/login/component/ResetPassword';
// import ChangePassword from './../view/login/component/ChangePassword';
//import NotFound from './../view/common/NotFound';

/* dealer detail */
//import DealerDetail from './../view/dealerDetail/component/MyAccount';

class CustomRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {ht:500}
    }
    componentDidMount(){
        this.setState({
            ht : window.innerHeight - 127
        });
        window.addEventListener('resize', () => {
            this.setState({
                ht : window.innerHeight - 127
            });
        }, false);
    }
    render() {
        const isLoggedIn = this.props.user_loggedin;
        return (
            <Router>
                {isLoggedIn == 1 ? <Header /> : ''}
                <div  style={{minHeight: this.state.ht}}>
                <Route path="/" exact component={Login} />
                <Route path="/login" component={Login} />
                
                <PrivateRoute path="/dashboard" component={Dashboard} />
                <PrivateRoute path="/report" component={Report} />
                <PrivateRoute path="/mobile-block" component={MobileBlock} />
                <PrivateRoute path="/add-mobile-block" exact component={AddMobileBlock} />
                <PrivateRoute path="/add-mobile-block/:id" component={AddMobileBlock} />
                <PrivateRoute path="/quality-control/:type?" component={QualityControl} />
                <Route path="/logout" component={Logout} />
                </div>
                {isLoggedIn == 1 ? <Footer /> : ''}
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        user_loggedin: state.userLoggedIn
    };
}
export default connect(mapStateToProps, null)(CustomRouter);
