import React, { Component } from "react";
import Cropper from "cropperjs" ;
import 'cropperjs/dist/cropper.css'
import MasterService from './../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { API_URL } from './../../config/constant';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const image2base64 = require('image-to-base64');
//const carouselContainer = document.querySelector(".carousel-container");

// Data for carousel
const carouselSlidesData = [
  {
    src:"",
    author: "Bane",
    source: "facebook"
  }, {
    content:
      "You have learn to bury your guilt with anger. I will teach you to confront it and to face the truth.",
    author: "Ra's Al Ghul",
    source: "Snapchat"
  }, {
    content:
      "Introduce a little anarchy, upset the established order and everything becomes chaos. I'm an agent of chaos. Oh, and you know the thing about chaos? It's fair.",
    author: "Joker",
    source: "facebook"
  }, {
    content:
      "I can't do that as Bruce Wayne... as a man. I'm flesh and blood. I can be ignored, destroyed. But as a symbol, I can be incorruptible, I can be everlasting.",
    author: "Bruce Wayne",
    source: "facebook"
  }, {
    content:
      "But it's not who you are underneath... it's what you do that defines you.",
    author: "Rachel Dawes",
    source: "twitter"
  }, {
    content:
      "When their enemies were at the gates the Romans would suspend democracy and appoint one man to protect the city. It wasn't considered an honor, it was a public service.",
    author: "John Blake",
    source: "Google+"
  }, {
    content:
      "Master Wayne, you've been gone a long time. You look very fashionable. Apart from the mud.",
    author: "Alfred Pennyworth",
    source: "twitter"
  }
];

class CarouselLeftArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className="carousel__arrow carousel__arrow--left"
        onClick={this.props.onClick}
      >
        <span className="crarrow left" />
      </a>
    );
  }
}

class CarouselRightArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className="carousel__arrow carousel__arrow--right"
        onClick={this.props.onClick}
      >
        <span className="crarrow right" />
      </a>
    );
  }
}

class CarouselIndicator extends Component {
  render() {
    return (
      <li>
        <a
          className={
            this.props.index == this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.onClick}
        />
      </li>
    );
  }
}
class CarouselThumb extends Component {
    render() {
      return (
        <li>
          <a
            className={
              this.props.index == this.props.activeIndex
                ? "thumb active"
                : "thumb"
            }
            onClick={this.props.onClick}
          >
              <div className="carousel-slideimg"><img src={`${this.props.slide.src}`} alt={this.props.slide.alt} /></div>
          </a>
        </li>
      );
    }
  }

class CarouselSlide extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      rotation: 0,
      src: null ,
      crop: {
        unit: '%',
        width: 30,
        aspect: 16 / 9,
      },
      cropperCanvas :false,
      cropperSet: false,
      loading: false,
      saveButton: false,
      actionType:'',

    };
    this.rotate = this.rotate.bind(this);  
  }
  
  rotate(){
    let newRotation = this.state.rotation + 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }
  rotateCropper=(image,rotationDegree) =>{
    //console.log( 'current status----->',this.state.rotation,'------------> ',rotationDegree);
    let degree = this.state.rotation+rotationDegree; 
    if(degree >= 360){
      degree =- 360;
    }else if(degree <= -360){
      degree = 360
    }

    let cropper = this.state.cropperCanvas;
    if(!cropper){
       cropper = new Cropper(image, {  
        ready: () => {
          cropper.rotate(degree);
          } 
      });
    }
    else
    cropper.rotate(degree);    
    this.setState({loading:false,cropperCanvas:cropper,rotation:degree })
  }
  rotateImage = (imageId, type)=>{    
    this.setState({loading:true,actionType:"rotate"});        
    var image = document.querySelector('#image_'+imageId);         
    var button = document.getElementById('save_'+imageId);
    var reset = document.getElementById('reset_'+imageId);
    let degree = 90;    
    if(type=='l'){
      degree = -90;
    }
    this.rotateCropper(image,degree);
    let cropper = this.state.cropperCanvas;
    // var cropper = new Cropper(image, {
    //   ready: () => {
    //     cropper.rotate(degree);
    //     this.setState({loading:false,saveButton:true});
    //   }
    // });
    button.onclick = (event) => {        
      event.preventDefault();
      let cropper = this.state.cropperCanvas;
      this.state.cropperCanvas.getCroppedCanvas().toBlob((blob) => {
      this.imageUploadToRemote(blob,imageId);      
      this.state.cropperCanvas.destroy(); 
      this.setState({saveButton:false,actionType:"",cropperCanvas:'',rotation:0});                        
      });
    }; 
    reset.onclick =(event) =>{   
      this.state.cropperCanvas.destroy();
      this.setState({saveButton:false,actionType:"",cropperCanvas:'',rotation:0});  
    }


  }
  cropImage=(imageId) =>{ 
        this.setState({loading:true,actionType:"cropImage"});        
        var image = document.querySelector('#image_'+imageId);         
        var button = document.getElementById('save_'+imageId);
        var reset = document.getElementById('reset_'+imageId);
        let cropper = new Cropper(image, {
          ready: (event) =>{
            // Zoom the image to its natural size  
            //console.log('intiate---');                    
            cropper.zoomTo(1);
            this.setState({loading:false,saveButton:true}); 

          },
          crop:(event) => {
            //console.log('crop---',JSON.stringify(cropper.getData()));
            //console.log(JSON.stringify(cropper.getCropBoxData()));            
            //cropper.getCroppedCanvas() ;
          },
          zoom: (event)=> {
            // Keep the image in its natural size
            // if (event.detail.oldRatio === 1) {
            //   event.preventDefault();
            // }
          },
        });
       // this.setState({loading:false,saveButton:true}); 
        button.onclick = (event) => {
          this.setState({loading:true,saveButton:false}); 
          event.preventDefault();
          cropper.getCroppedCanvas().toBlob((blob) => {
          this.imageUploadToRemote(blob,imageId);
          cropper.destroy();  
          this.setState({loading:false,saveButton:false,actionType:""});                       
          });
        };
        reset.onclick =(event) =>{          
          cropper.destroy();
          this.setState({saveButton:false,actionType:""});  
        }
      
  }  
  imageUploadToRemote =(blob,imageId)=>{
    this.setState({loading:true,buttonDisable:true});  
    const formData = new FormData();    
    formData.append('upload_type','inventory_car_images');
    formData.append('images', blob, 'abc.jpg' );
    let headerObj = {"content-type":"multipart/form-data;"};
    MasterService.post('core/commonservice/docs_upload ',formData,headerObj)
      .then( (response) => {         
          if(response.data.data && response.data.data.length>0){            
            this.props.updateCarImages(response.data.data[0],imageId);
            this.setState({loading:false,buttonDisable:false});  
          }
          this.setState({loading:false,saveButton:false,actionType:""});  
      })
    .catch(error => {
      toast.error(error);       
    });

  }
 

  maskImage = async (imageId) => {
    this.setState({loading:true,actionType:"maskImage"});  
    let image = document.querySelector('#image_'+imageId);
    
    image = await image2base64(image.src);
    document.querySelector('#image_'+imageId).src = 'data:image/jpeg;base64,'+image;
    image = document.querySelector('#image_'+imageId);
    //console.log(image);
    //image.crossOrigin = "anonymous";         
    let button = document.getElementById('save_'+imageId);   
    var reset = document.getElementById('reset_'+imageId);    
    let croppable = false;
    let cropper = new Cropper(image, {
        viewMode: 1,
        guides: true,
        center: true,
        highlight: true,
        checkImageOrigin:false,
        initialAspectRatio:11,
        cropBoxMovable: true,
        cropBoxResizable: true,       
        ready: () =>{
          croppable = true;
          this.setState({loading:false,saveButton:true}); 
        },
      });
      //this.setState({loading:false});
      button.onclick =()=> {  
        //console.log('------------->',image.src);        
        image.crossOrigin = "anonymous";  
        var maskedImage;
        var maskedCanvas;
        if (!croppable) {
          return;
        }
        this.setState({loading:true,saveButton:false});
        // Crop
        let croppedCanvas = cropper.getCroppedCanvas();
        // Mask        
        maskedCanvas = this.getMaskedCanvas(croppedCanvas, image, cropper);
        maskedCanvas.toBlob((blob) => {
            this.imageUploadToRemote(blob,imageId); 
             cropper.destroy();  
            this.setState({loading:false,saveButton:false,actionType:""});                              
        });        
        
      }
      reset.onclick =(event) =>{
        cropper.destroy();
        this.setState({saveButton:false,actionType:""});  
      }

  }

  getMaskedCanvas =(sourceCanvas, sourceImage, cropper) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let maskWidth = cropper.getData().width;
    let maskHeight = cropper.getData().height;
    let maskTop =  cropper.getData().y;
    let maskLeft =  cropper.getData().x;
    let imageWidth = cropper.getImageData().naturalWidth;
    let imageHeight = cropper.getImageData().naturalHeight;
    canvas.width = imageWidth;
    canvas.height = imageHeight;
    context.imageSmoothingEnabled = true;
    context.fillStyle = "#FFFFFF";
    context.drawImage(sourceImage, 0, 0, imageWidth, imageHeight);
    context.fillRect(maskLeft, maskTop, maskWidth, maskHeight);
    return canvas;
  }
  openGalleryImage = (imageId) =>{
      console('carosal-',imageId);
  }

  render() {
    const { rotation } =  this.state;
    return (
      <li
        className={
          this.props.index == this.props.activeIndex
            ? "carousel__slide carousel__slide--active"
            : "carousel__slide"
        }
        
      >
        <div className="imageactionBtn" id ={"img-gallery-"+this.props.slide.id} >
          <button onClick={()=>this.rotateImage(this.props.slide.id,'l')} type="button"  disabled={(this.state.actionType==''|| this.state.actionType==='rotate')? false:true } className="btn btn-link mrg-r15" value="left" ><i className="ic-replay icons-normal"></i></button>
          <button onClick={()=>this.rotateImage(this.props.slide.id,'r')} type="button"  disabled={(this.state.actionType==''|| this.state.actionType==='rotate')? false:true } className="btn btn-link mrg-r15" value="right" ><i className="ic-refresh icons-normal"></i></button>
          <button onClick={() => this.cropImage(this.props.slide.id)} type="button" disabled={(this.state.actionType=='')? false:true } className="btn btn-link mrg-r15" value="" ><i className="ic-crop icons-normal"></i></button>
          <button onClick={() => this.maskImage(this.props.slide.id)} type="button" disabled={(this.state.actionType=='')? false:true }  alt="Crop"  className="btn btn-link mrg-r15" value="" ><i className="ic-blur_circular icons-normal"></i></button>
          <button type="button" id={"save_"+this.props.slide.id} className={(this.state.saveButton || this.state.actionType==='rotate') ? 'btn btn-primary mrg-r15 ' : "btn btn-primary mrg-r15 hide"} >{this.props.t('qualityControl.save')}</button>
          <button type="button" id={"reset_"+this.props.slide.id} className={(this.state.saveButton|| this.state.actionType==='rotate') ? 'btn btn-default ' : "btn btn-default hide"} >{this.props.t('qualityControl.reset')}</button>
        </div>
        <div className={this.state.loading?"carousel-slide loading":"carousel-slide" } >
        <LazyLoadImage
          alt={this.props.slide.alt}
          id={"image_"+this.props.slide.id}
          effect="blur"
          src={`${API_URL.GETWAY_API}qc/user/edit-image?url=${encodeURIComponent(this.props.slide.src)}`} // use normal <img> attributes as props
           />
          {/* <img src={`${API_URL.GETWAY_API}qc/user/edit-image?url=${this.props.slide.src}`} id={"image_"+this.props.slide.id} alt={this.props.slide.alt}  style={{transform: `rotate(${rotation}deg)`}}/> */}
        </div>
        {/* <div className="carousel-slideimg"><img src={this.props.slide.src} alt={this.props.slide.alt} /></div> */}

        {/* <p>
          <strong className="carousel-slide__author">
            {this.props.slide.author}
          </strong>,
          {" "}
          <small className="carousel-slide__source">
            {this.props.slide.source}
          </small>
        </p> */}
      </li>
    );
  }
}

// Carousel wrapper component
class Carousel extends Component {
  constructor(props) {
    super(props);

    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      activeIndex: (this.props.galleryImageId)? this.props.galleryImageId :0,
      rotation: 0
    };
    
  }
  
 

  goToSlide(index) {
    this.setState({
      activeIndex: index
    });
  }  

  goToPrevSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length;

    if (index < 1) {
      index = slidesLength;
    }

    --index;

    this.setState({
      activeIndex: index
    });
  }

  goToNextSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length - 1;

    if (index === slidesLength) {
      index = -1;
    }

    ++index;

    this.setState({
      activeIndex: index
    });
  }

  render() {
   
    return (
      <div className="carousel">
        
        <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} />

        <ul className="carousel__slides">
          {this.props.slides.map((slide, index) =>
            <CarouselSlide
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              slide={slide}
              updateCarImages={this.props.updateCarImages}
              t={this.props.t}
              galleryImageIndex={this.props.galleryImageId}
            />
          )}
        </ul>

        <CarouselRightArrow onClick={e => this.goToNextSlide(e)} />

        <ul className="carousel__indicators">
          {this.props.slides.map((slide, index) =>
            <CarouselIndicator
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              isActive={this.state.activeIndex==index} 
              onClick={e => this.goToSlide(index)}
            />
          )}
        </ul>
        <div className="thumbbx">
            <ul className="carouse-thumb">
          {this.props.slides.map((slide, index) =>
            <CarouselThumb
              key={index}
              index={index}
              activeIndex={this.state.activeIndex}
              isActive={this.state.activeIndex==index} 
              onClick={e => this.goToSlide(index)}
              slide={slide}
              
            />
          )}
        </ul>
        </div>
        
      </div>
    );
  }
}
//render(<Carousel slides={carouselSlidesData} />, carouselContainer);

//export default Carousel;
const mapStateToProps = state => {
  return {
      auth_token: state.authToken,
      user_loggedin: state.userLoggedIn,
      //dealer_id: state.dealer_id
  };
}

const mapDispatchToProps = dispatch => {
  return {
      updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
export default withTranslation('qc')(connect(mapStateToProps, mapDispatchToProps)(Carousel));
//export default withTranslation('qc')(Carousel);
// Render Carousel component

