import { Link } from "react-router-dom";
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import secureStorage from '../../config/encrypt';
import { connect } from 'react-redux';
import Dropdown from "../elements/Dropdown";
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { DealerService } from '../../service';
import { withTranslation } from 'react-i18next';
import MasterService from './../../service/MasterService';
class SellerInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            langData: {},
            loading:true,
            car_hash: props.car_hash,
            car_id:props.car_id,
            basic_details: {},
        };
    }
    componentDidMount = async () => {
        this.getSellerDetails();
    }

    getSellerDetails() {
        if (this.state.car_hash) {
            let postdata = { car_id_hash: this.state.car_hash,_with:["owner"]}
            let car_id = this.state.car_id;
            DealerService.getBasicDetails(postdata).then(response => {
                if (response.data.status == 200 && response.status == 200) {
                    let responsedata = response.data;
                    console.log("responsedata",responsedata);
                    let datasend = {},post_data = {};
                    if (responsedata.data.length > 0) {
                        datasend = responsedata.data[0];
                        this.setState({ basic_details: datasend, loading: false }, () => {
                            if (secureStorage.getItem('loginUserInfo')) {
                                let loginResponse = secureStorage.getItem('loginUserInfo');  
                                if (loginResponse && loginResponse.data && loginResponse.data.user_data.user_name) {
                                    let user_id = loginResponse.data.user_data.id;
                                    post_data = {
                                        login_user:user_id,
                                        car_id:car_id,
                                        type:datasend.type
                                    };
                                    console.log("post_data",post_data);
                                    this.maintainHistory(post_data);
                                }
                            }
                        })
                    }
                } else {
                    toast.error((response.data.message) ? response.data.message : "Error")
                }
            }).catch(error => {
                toast.error(error.message);
            });
        } else {
            this.setState({ loading: false })
        }
    }

    maintainHistory(post_data) {
        console.log("post_data maintainHistory:::",post_data);
        MasterService.post('qc/qc/history/create_history', post_data).then(response => {
            if (response.data.status == 200 && response.status == 200) {
            }
        }).catch(error => {
            toast.error(error.message);
        });
    } 
    

    render() {
        let basic_details = this.state.basic_details;
        return (
            <div>
               <p><b>{this.props.t('qualityControl.dealer_name')}: </b>{basic_details.organization}</p>
               <p><b>{this.props.t('qualityControl.owner_name')}: </b>{basic_details.owner ? basic_details.owner.name:''}</p>
               <p><b>{this.props.t('qualityControl.contact')}: </b>{basic_details.contact ? basic_details.contact.SMS_buyer_lead:''}</p>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        name: state.name,
        user_loggedin: state.userLoggedIn
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withTranslation('qc')(connect(mapStateToProps, mapDispatchToProps)(SellerInformation));
