import React from 'react';
import { Link } from 'react-router-dom';

class Dropdown extends React.Component {
    constructor(){
        super()
        this.state = {
            
        }
      }
      handleClickOutside(){
        this.setState({
          listOpen: false
        })
      }
      toggleList(){
        this.setState(prevState => ({
          listOpen: !prevState.listOpen
        }))
      }
      toggleSelected(id, key){
        let temp = this.state[key]
        temp[id].selected = !temp[id].selected
        this.setState({
          [key]: temp
        })
      }
      static getDerivedStateFromProps(nextProps){
        const count = nextProps.list.filter(function(a) { return a.selected; }).length;
        console.log(count)
        return {headerTitle: nextProps.title}
    
    
      }
    
    render() {
        const{list} = this.props
  const{listOpen, headerTitle} = this.state
    return (
            <div className="dropdown">
                <a className="dropdown-toggle" onClick={() => this.toggleList()}>
        {headerTitle}
                <span className="caret"></span></a>
                {listOpen && <ul className="dropdown-menu">
       {list.map((item) => (
         <li key={item.id} ><Link to={item.title}>{item.title}</Link> </li>
        ))}
      </ul>}
            </div>
               
        );
    }
}
export default Dropdown;