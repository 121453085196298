'use strict'

import React from 'react';
//import { HtmlTag } from 'elements';

const defaultProps = {
	error: {
		status: true,
		message:'',
	},
	popupCls:""
}
class CheckBox extends React.PureComponent {

	constructor(props) {
		super(props);
	
		this.state = {
		  active: (props.locked && props.active) || false,
		  value: props.value || "",
		  id: props.id || "",
		  name: props.name || "",
		  type: props.type || "type",
          error: props.error || "",
          checked: props.checked || "",
          
		  label: props.label || "Label"
		};
	  }
	
	changeValue(event) {
		const value = event.target.value;
		this.setState({ value, error: "" });
	  }

	  componentWillReceiveProps(nextProps) {
        this.setState({ 
            checked: nextProps.checked
        });
    }
	

	render() {
		const { active, value, error, checked, label, id, type, name } = this.state;
		//let error = this.props.error;
		return (
			
			<label htmlFor={id} className={"gs_control gs_radio"}>
            {label}
          
                <input  id={id}
					className="form-control"
					type={type}
					value={value}
					name={name} 
					checked={checked}
					onChange={this.props.onChange}
					disabled={this.props.disabled}
					/>
                    <span className="gs_control__indicator"></span>
                </label>
            
		);
	}
}
CheckBox.defaultProps = defaultProps;
export default CheckBox;