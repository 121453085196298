import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import secureStorage from './../../config/encrypt';
import MasterService from './../../service/MasterService';
import QualityControl from './../quality-control/QualityControl';
import {CONF_VAL} from '../../config/constant';
class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            langOption : [],
            qcType :"dealer",
        }
    }
    componentWillMount = ()=>{
        let localLangData = secureStorage.getItem('langOption');
        if(!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response)=>{
                if (response.status == 200 && response.data.status == 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    this.setState({langOption: langData});
                }
            });
        }else{
            this.setState({langOption: localLangData});
        }
    }

    handleLogout = () => {
        if (typeof this.props.logoutUser === 'function') {
            this.props.logoutUser();
        }
    }

    handleDealerType = (type) =>{

        secureStorage.setItem('qcType', type);
        this.setState({qcType:type});
        
    }
    capitalize= (stringData) =>
    {
        return stringData && stringData[0].toUpperCase() + stringData.slice(1);
    }
    render() {
        let name = "Admin";
        let role;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');  
            if (loginResponse && loginResponse.data && loginResponse.data.user_data.user_name) {
                name = this.capitalize(loginResponse.data.user_data.user_name)|| 'Admin';
                //role = loginResponse.data.user_type;
            }
        }

        const { t, i18n } = this.props;
        const selLang = secureStorage.getItem('lang') || 'en';
        const {langOption} = this.state;
        return (
            <nav className={this.props.navClass}>
                <ul className="navbar-nav">
                <li><NavLink to={{ pathname: '/dashboard', state: 'freshQcDashboard' }}  exact>{this.props.t('menu.dashboard')}</NavLink></li>
                    {/*role == 'admin' &&
                        <li className="dropdown"><NavLink to="/">{this.props.t('menu.dashboard')}<span className="caret"></span></NavLink>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to="/admin/dealer-list">{this.props.t('menu.dealer_manager')}</Link>
                                </li>
                            </ul>
                    </li> */}
                    <li  className="dropdown"><NavLink to={{ pathname: '/quality-control', state: 'freshQc',qcType:'dealer' }} >{this.props.t('menu.quality_control')}</NavLink>
                        <ul className="dropdown-menu">
                            <li onClick={() => this.handleDealerType("dealer")}><NavLink isActive={(match, location) => {
                                    if (location.pathname == '/quality-control/dealer' || location.pathname == '/quality-control') { return true; }
                                }}  to={{ pathname: '/quality-control/dealer', state: 'freshQc',qcType:'dealer' }}>{this.props.t('menu.dealer_type')}</NavLink></li>
                            <li onClick={() => this.handleDealerType("individual")}><NavLink  isActive={(match, location) => {
                                    if (location.pathname == '/quality-control/individual' ) { return true; }
                                }} to={{ pathname: '/quality-control/individual', state: 'freshQc',qcType:'individual' }}>{this.props.t('menu.'+CONF_VAL.individual)}</NavLink></li>
                        </ul>                    
                    </li>
                    
                    <li><NavLink to={{ pathname: '/report', state: 'freshQcReport' }}  exact>{this.props.t('menu.report')}</NavLink></li>
                    <li><NavLink to={{ pathname: '/mobile-block', state: 'freshData' }}  exact>{this.props.t('menu.mobile_block')}</NavLink></li>
                    
                    <li className="dropdown myac"><a href="">
                        <span className="userac"><i className="ic-person"></i></span>
                        {this.props.t('menu.hi')} {name}<span className="caret"></span></a>
                        <ul className="dropdown-menu">
                            {role == 'dealercentral' && <li><NavLink to="/my-account/basic-details">{this.props.t('menu.my_account')}</NavLink></li>}
                            <li onClick={this.handleLogout}><NavLink to="">{this.props.t('menu.logout')}</NavLink></li>
                        </ul>
                    </li>
                    <li>
                        <div className="language-change">
                            {langOption.length > 1 ?
                            langOption.map((lang, index) =>
                                <button key={index} type='button' className={selLang==lang.iso_code?'btn btn-primary':'btn btn-default'} onClick={() => {
                                    secureStorage.setItem('lang', lang.iso_code);
                                    i18n.changeLanguage(lang.iso_code)
                                }}>{lang.iso_code}</button>
                            ) :''}
                        </div>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default withTranslation('common')(Navbar);