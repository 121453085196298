//import { Link } from "react-router-dom";
import React, { Component } from 'react';
import secureStorage from '../../config/encrypt';
import { connect } from 'react-redux';
import { InventoryService } from '../../service';
import { withTranslation } from 'react-i18next';
import { Redirect, Link } from 'react-router-dom';
import InputField from '../elements/InputField';
import { toast } from 'react-toastify';

class AddMobileBlock extends Component {
  constructor(props) {
    super(props);        
    this.state = {
      redirect: false,
      blockMobileData: {},
      redirectToList: false,
      errors: {}
    };
  }
  updateReduxData = async () => {
    if (secureStorage.getItem('loginUserInfo')) {
      var loginResponse = secureStorage.getItem('loginUserInfo');
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      this.afterLoginSetState(loginResponse)
    } else {
      this.setState({ redirect: '/login' })
    }
  }
  afterLoginSetState = (loginResponse) => {    
    let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.user_type || null;
    this.setState({ role:role,user_id:loginResponse.data.user_data.id })
  }
  componentDidMount = async () => {
    await this.updateReduxData();
    if(this.props.match.params.id){
      await this.getBlockMobileById(this.props.match.params.id);    
    }    
  }

  getBlockMobileById = async (id) =>{
        this.setState({loading:true});
        InventoryService.getBlockMobileById({id: id})
        .then( (response) => {
          if (response.data.status == 200 && response.data.data.length) {                               
              this.setState({
                blockMobileData: {
                  mobile: response.data.data[0].mobile,
                  email: response.data.data[0].email,
                  ip_address: response.data.data[0].ip_address,
                  id: response.data.data[0].id
                }
              });
          }
          else{
            this.setState({redirectToList: true})
          }
          this.setState({loading:false});                
      })
      .catch(error => { });        
  }

  validFormData = () => {
    let blockMobileData = this.state.blockMobileData;

    let errors = {};
    let formIsValid = true;

    if (!blockMobileData['mobile']) {
        formIsValid = false;
        errors["mobile"] = this.props.t('qualityControl.validation_error.mobile_is_required');
    }

    if (blockMobileData['email'] && !this.ValidateEmail(blockMobileData['email'])) {
      formIsValid = false;
      errors["email"] = this.props.t('qualityControl.validation_error.invalid_email');
    }

    if (blockMobileData['ip_address'] && !this.ValidateIPaddress(blockMobileData['ip_address'])) {
      formIsValid = false;
      errors["ip_address"] = this.props.t('qualityControl.validation_error.invalid_ip');
    }

    this.setState({ errors: errors })
    return formIsValid;
  }

  ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
      return (true)
    }      
    return (false)
  }

  ValidateIPaddress = (ipaddress) => {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)){
      return (true)
    }
    return (false)
  }

  submitAddForm = (event) => {
    event.preventDefault();
    if (this.validFormData()) {
        let blockMobileData = { ...this.state.blockMobileData };        
        this.setState({ loading: true });
        let thisObj = this;
        InventoryService.addBlockMobile(blockMobileData)
            .then((response) => {                                       
                this.setState({ loading: false });
                if (response.data.status == 200) {                        
                    if (response.data.data) {
                        if(!this.state.blockMobileData.id){
                          this.setState({
                            blockMobileData: {
                                  ...this.state.blockMobileData,
                                  id: response.data.data.id
                              }
                          })
                          toast.success('added successfully');                 
                        }
                        else{
                          toast.success('updated successfully');
                        }                                                                                                     
                    }                    
                } else {
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                toast.error(error);
                this.setState({ loading: false });
            })
    }
  }

  handleChangetext = (event) => {
    let blockMobileData = {...this.state.blockMobileData};
    blockMobileData[event.target.name] = event.target.value;
    this.setState({
      blockMobileData: blockMobileData
    });
  }
 
  render() {
    if (this.state.redirectToList) {
      return (<Redirect to={'/mobile-block'} />);
    }

    const {blockMobileData, errors} = this.state;
    return (
      <div className="">
        <div className={this.state.loading?"container-fluid loading":"container-fluid" }>
          <h1 className="card-title">{this.props.t('qualityControl.mobile_block')} </h1> 
          <form method="POST" className="addStock_form formHeight"> 
            <div className="card">     
              <div className="card-body">    
                  <div className="row">
                    <div className="col-sm-3 form-group">                    
                        <InputField
                          inputProps={{
                              id: "mobile",
                              type: "text",
                              placeholder: this.props.t('qualityControl.mobile'),
                              name: "mobile",
                              autoComplete: "off",
                              label: this.props.t('qualityControl.mobile'),
                              value: (blockMobileData.mobile) ? blockMobileData.mobile : '',
                              validation_error: errors.mobile
                          }}
                          onChange={this.handleChangetext}
                      />                      
                    </div>                
                  </div>

                  <div className="row">
                    <div className="col-sm-3 form-group">                    
                        <InputField
                          inputProps={{
                              id: "email",
                              type: "text",
                              placeholder: this.props.t('qualityControl.email'),
                              name: "email",
                              autoComplete: "off",
                              label: this.props.t('qualityControl.email'),
                              value: (blockMobileData.email) ? blockMobileData.email : '',
                              validation_error: errors.email
                          }}
                          onChange={this.handleChangetext}
                      />                      
                    </div>                
                  </div>

                  <div className="row">
                    <div className="col-sm-3 form-group">                    
                        <InputField
                          inputProps={{
                              id: "ip_address",
                              type: "text",
                              placeholder: this.props.t('qualityControl.ip_address'),
                              name: "ip_address",
                              autoComplete: "off",
                              label: this.props.t('qualityControl.ip_address'),
                              value: (blockMobileData.ip_address) ? blockMobileData.ip_address : '',
                              validation_error: errors.ip_address
                          }}
                          onChange={this.handleChangetext}
                      />                      
                    </div>                
                  </div>  

                  <div className="row mrg-b30">
                      <div className="col-sm-3 form-group">
                          <button type="submit" id="savedetail" name="submit" onClick={this.submitAddForm} className="btn btn-success btn-lg" >{this.props.t('qualityControl.save')}</button>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <Link to={`/mobile-block`}>
                            <button type="button" className="btn btn-primary btn-lg" >{this.props.t('qualityControl.cancel')}</button>
                          </Link> 
                      </div>                      
                  </div>                          

              </div>  
            </div>
          </form>                 
        </div>
      </div>    
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.name,
    user_loggedin: state.userLoggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withTranslation('qc')(connect(mapStateToProps, mapDispatchToProps)(AddMobileBlock));
