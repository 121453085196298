//import { Link } from "react-router-dom";
import React, { Component } from 'react';
import secureStorage from '../../config/encrypt';
import { connect } from 'react-redux';
import { InventoryService } from '../../service';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

class MobileBlock extends Component {
  constructor(props) {
    super(props);    
    
    this.state = {
      redirect: false,
      blockMobile: [],
      searchText: ''
    };
  }
  updateReduxData = async () => {
    if (secureStorage.getItem('loginUserInfo')) {
      var loginResponse = secureStorage.getItem('loginUserInfo');
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      this.afterLoginSetState(loginResponse)
    } else {
      this.setState({ redirect: '/login' })
    }
  }
  afterLoginSetState = (loginResponse) => {    
    let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.user_type || null;
    this.setState({ role:role,user_id:loginResponse.data.user_data.id })
  }
  componentDidMount = async () => {
    await this.updateReduxData();
    await this.getBlockMobile();    
  }

  componentWillReceiveProps = async(nextProps) => {
    if(nextProps.history.location.state == 'freshData'){
      await this.updateReduxData();
      await this.getBlockMobile();
    }
  }
  getBlockMobile = () => {
        this.setState({loading:true});
        InventoryService.getBlockMobile({search: this.state.searchText})
        .then( (response) => {
          if (response.data.status == 200) {                 
              let blockMobileArr = [];                 
                response.data.data.forEach(function (result) {
                  blockMobileArr.push(result);                        
              });
              this.setState({blockMobile: blockMobileArr});
          }
          this.setState({loading:false});                
      })
      .catch(error => { });        
  }  
  
  handleSearchText = (event) => {
    this.setState({
      searchText: event.target.value
    });
  }

  resetBlockedMobile = () => {
    this.setState({
      searchText: ''
    }, () => {
      this.getBlockMobile();
    });
  }

  changeStatus = (data, index) => {
    this.setState({ loading: true });
    let newStatus = (data.status == 1)? 0: 1;
    InventoryService.changeBlockedMobileStatus({status: newStatus, id: data.id })
      .then((response) => {                                       
          this.setState({ loading: false });
          if (response.data.status == 200) {                        
            let blockMobile = [...this.state.blockMobile]
            blockMobile[index].status = newStatus;
            this.setState({
              blockMobile: blockMobile
            });
          } else {
              toast.error(response.data.message);
          }
      }).catch((error) => {
          toast.error(error);
          this.setState({ loading: false });
      })        
  }

  render() {    
    return (
      <div className="">
        <div className={this.state.loading?"container-fluid loading":"container-fluid" }>
        <h1 className="card-title">{this.props.t('qualityControl.mobile_block')} </h1>  
          <div className="search-wrap">
            <div className="row">
            <div className="col-sm-6 col-md-2">
            <input type="search" name="searchbyid" id="searchbyid" autoComplete="off" placeholder={this.props.t('qualityControl.search')} 
                className="form-control" value={this.state.searchText} onChange={this.handleSearchText} />                
              </div>              
              <div className="col-sm-6 col-md-2">
                <button type="button"  onClick={this.getBlockMobile} className="btn btn-primary" >{this.props.t('dashboard.search')}</button>
                <button type="button"  onClick={this.resetBlockedMobile} className="btn btn-reset" >{this.props.t('dashboard.reset')}</button>
              </div>
              <div className="col-sm-6 col-md-2">
                  <Link to={`/add-mobile-block`}>
                    <button type="button" className="btn btn-success" >{this.props.t('dashboard.add')}</button>
                  </Link> 
              </div>
            </div>
          </div>        
            <div id="table_data">             
              <div className="card  mrg-T20">
                <div className="">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>                          
                          <th>{this.props.t('qualityControl.mobile')}</th>
                          <th>{this.props.t('qualityControl.email')}</th>                          
                          <th>{this.props.t('qualityControl.ip_address')}</th>                  
                          <th>{this.props.t('qualityControl.status')}</th>                                          
                          <th>{this.props.t('qualityControl.action')}</th>                       
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.blockMobile.map((data, index) => 
                      <tr key={index} >
                        <td>{data.mobile}</td>
                        <td>{data.email} </td>                        
                        <td>{data.ip_address }</td>                    
                        <td>
                          <a href="#" onClick={() => this.changeStatus(data, index)}>
                            {data.status == 1 ? 'Active':'Inactive' }
                          </a>                          
                        </td>                    
                        <td>
                        <Link to={`/add-mobile-block/${data.id}`}>
                          <button type="button" className="btn btn-default mrg-r10" title={this.props.t('qualityControl.edit')}>
                            <i className="ic-createmode_editedit"></i>
                          </button>
                        </Link>                                                   
                        </td>
                      </tr>  
                    )}                                                      
                    </tbody>
                </table>
              </div>
            </div>
          </div>          
        </div>
      </div>
    
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.name,
    user_loggedin: state.userLoggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withTranslation('qc')(connect(mapStateToProps, mapDispatchToProps)(MobileBlock));
