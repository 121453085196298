import React from 'react';
import { Route, Redirect } from "react-router-dom";
import secureStorage from './../config/encrypt';
const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={props =>
          secureStorage.getItem('loginUserInfo') ? 
              <Component {...props} />
              :
              <Redirect to="/login" />
        }
      />
    );
};

export default PrivateRoute;