import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CheckBox from './../elements/CheckBox';
import Button from './../elements/Button';
import InputField from '../elements/InputField';

class RejectReason extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checkedItems: new Map(),
            itemChecked: {}
        }

        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount = async() => {
        await this.setSelectedIssue();
    }
    setSelectedIssue = ()=> {
        //console.log('-----rejectedImageIssue ----', this.props.rejectedImageIssue); 
        let alreadyChecked = this.state.itemChecked;

        if( this.props.rejectedImageIssue.length>0){
            this.props.rejectedImageIssue.map(item => {
                if(!alreadyChecked.hasOwnProperty(item)){
                    alreadyChecked[item] = true;
                }else{
                    alreadyChecked[item] = (alreadyChecked[item]) ? false : true;
                }
                //console.log('------item---',item);   
               // this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, true) }));
            });
            this.setState({itemChecked: alreadyChecked},()=>{})
        }
    }

    showList = () => this.setState(prevState => ({
        showActionFilterList: !prevState.showActionFilterList
    }));

    handleChange(e) {
        const item = e.target.name;
        const itemId = e.target.id;
        const isChecked = e.target.checked;
        let alreadyChecked = this.state.itemChecked;
        if(!alreadyChecked.hasOwnProperty(itemId)){
            alreadyChecked[itemId] = true;
        }else{
            alreadyChecked[itemId] = (alreadyChecked[itemId]) ? false : true;
        }
        //console.log('-----------ischecked--------',isChecked);
        this.setState({itemChecked: alreadyChecked},()=>{})
        this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(itemId, isChecked) }),()=>{
           this.props.handleRejectAllImages(item,itemId,isChecked);
        });
    }
   

    render() {
        let {itemChecked} = this.state;
        return (
            <div>
                {
                    
                    this.props.reasonArr.map(item => (
                        
                        <InputField 
                            inputProps={{
                                id: item.id,
                                type: "checkbox",
                                name: item.issue_name,
                                value: 1,
                                label: item.issue_label,
                                key: item.id,
                                checked: (itemChecked[item.id]) ? true : false
                            }}
                            onChange={this.handleChange}
                        />
                        
                                
                    ))
                   
                }
            </div>
            
        )
    }
}

export default RejectReason;
