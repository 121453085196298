import { Link } from "react-router-dom";
import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import secureStorage from '../../config/encrypt';
import { connect } from 'react-redux';
import Dropdown from "../elements/Dropdown";
import axios from 'axios';
import Select from 'react-select';
import { DealerService } from '../../service';
import { withTranslation } from 'react-i18next';
import MasterService from './../../service/MasterService';
import {CONF_VAL} from '../../config/constant';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
    this.state = {
      redirect: false,
      qcIssueCount:[],
      qcStatus: [],
      inventoryStatus:{},
      loading: false,
      role :"admin",
      user_id : "",
      approved : 0,
      flagged : 0,
      parttiallApproved : 0,
      langData: {},
    };
  }
  updateReduxData = async () => {
    if (secureStorage.getItem('loginUserInfo')) {
      var loginResponse = secureStorage.getItem('loginUserInfo');
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      this.afterLoginSetState(loginResponse);
      let langData = {} ;
      let langOption = secureStorage.getItem('langOption');
      let lang = secureStorage.getItem('lang')
      if(langOption && langOption.length > 0 ){
          langData.langOption = langOption;
          langData.lang = lang;
          langData.langId = (langOption.filter(v => v.iso_code == lang).map(v => v.id))[0];
          this.setState({langData:langData});
      }

    } else {
      this.setState({ redirect: '/login' })
    }
  }
  afterLoginSetState = (loginResponse) => {
    let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
    this.setState({ role:role,user_id:loginResponse.data.user_data.id })
  }
  componentDidMount = async () => {   
    await this.updateReduxData();
    await this.getIssueCount();
    await this.getQcStatus();
    await this.getTotalQc();
    
    //await this.getdashboardleadcard(this.state.formData && this.state.formData.lead_report);
  }
  componentWillReceiveProps = async(nextProps) => {
    if(nextProps.history.location.state == 'freshQcDashboard'){
      await this.updateReduxData();
      await this.getIssueCount();
      await this.getQcStatus();
      await this.getTotalQc();
    }
  }
  getIssueCount = async () => {
    this.setState({loading:true});
    let headerObj = {"accept-language":this.state.langData.lang};
      MasterService.post('qc/qc/car-issue/get-count',{langId:this.state.langData.langId||1},headerObj)
        .then( (response) => {                
          if (response.data.status == 200) { 
              this.setState({ qcIssueCount: response.data.data }, () => { 
                //console.log(this.state.qcIssueCount);
              })                                                                       
          }
          else {
            this.handelError(this, response.data);
          }                
        })
        .catch(error => {
          this.setState({loading:false});
      });
  }
  getTotalQc = () =>{
    let headerObj = {"accept-language":this.state.langData.lang};
    MasterService.post('inventory/inventory/qc_status_count',{},headerObj)
        .then( (response) => {
            if(response.data.status=='200'){
                this.setState({inventoryStatus:{
                  need_qc:response.data.data.no_qc,
                  redo_qc: response.data.data.redo_qc,
                  qc_done : response.data.data.qc_done,
                  active_stock : response.data.data.active_stock,
                }
              });
              
            }
            else
            {
                this.handelError(this, response.data);
            }
            this.setState({loading:false});
        })
        .catch(error => {
          this.setState({loading:false});
        });
}
  getQcStatus = async () => {
    MasterService.post('qc/qc/user/report',{type:1})
      .then( (response) => {                
        if (response.data.status == 200 && response.data.data.length >0 ) {
          response.data.data.map((resultData)=>{
            //console.log(resultData.qc_state)
            if(resultData.qc_state=='approved'){ 
              this.setState({approved:resultData.total_count }); 
            }
            else if (resultData.qc_state=='flagged'){ 
              this.setState({flagged:resultData.total_count }); 
            }
            else if (resultData.qc_state=='need_qc' || resultData.state=='redo_qc'){ 
              let parttiallApproved =  this.state.parttiallApproved;
              this.setState({parttiallApproved:parttiallApproved+parseInt(resultData.total_count)}); 
            }            
          });         
        }
        else {
          this.handelError(this, response.data);
        }                
      })
      .catch(error => {
        this.setState({loading:false});
    });
 
  }


  render() {
    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect} />);
    }

    return (
      <div className={ (this.state.loading)? "dashboard loading" : "dashboard"} >
        <div className="container-fluid">
          <div className="card background-efOne mrg-T20">
            <div className="card-heading">
              <h2 className="card-title">
                <div>
                {this.props.t('dashboard.heading')}
                </div>
                
              </h2>
            </div>
            <ul className="lead-list row">
              <li className="col-sm-3 col-md-2"> 
                  <div className="leadno"> {this.state.inventoryStatus.active_stock}</div>
                  <div className="leadlabel">{this.props.t('dashboard.'+CONF_VAL.activeStock)}</div>
              </li>
              <li className="col-sm-3 col-md-2">
                  <div className="leadno">{this.state.inventoryStatus.need_qc}</div>
                  <div className="leadlabel">{this.props.t('qualityControl.'+CONF_VAL.need_qc)} </div>
              </li> 
              <li className="col-sm-3 col-md-2">
                  <div className="leadno">{this.state.inventoryStatus.redo_qc}</div>
                  <div className="leadlabel">{this.props.t('qualityControl.'+CONF_VAL.redo_qc)} </div>
              </li> 
              <li className="col-sm-3 col-md-2">
                  <div className="leadno">{this.state.approved}</div>
                  <div className="leadlabel">{this.props.t('dashboard.approved')} </div>
              </li> 
              <li className="col-sm-3 col-md-2">
                  <div className="leadno">{this.state.parttiallApproved}</div>
                  <div className="leadlabel">{this.props.t('dashboard.partiallyApproved')} </div>
              </li> 
              <li className="col-sm-3 col-md-2">
                  <div className="leadno">{this.state.flagged}</div>
                  <div className="leadlabel">{this.props.t('dashboard.rejected')} </div>
              </li>                       
            </ul>                      
          </div>
          <div className="card  mrg-T20">
            <div className="card-heading">
              <h2 className="card-title">
                <div>
                {this.props.t('dashboard.'+CONF_VAL.issueCount)}
                </div>
                
              </h2>
            </div>
            <table className="table table-bordered table-striped">
              <tbody>
              {this.state.qcIssueCount.map((issueData, index) =>
                <tr key={index}>
                  <td>{issueData.issue_label}</td>
                  <td>{issueData.total}</td>
                </tr>
                )} 
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    name: state.name,
    user_loggedin: state.userLoggedIn
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
// export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withTranslation('qc')(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
