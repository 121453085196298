import React, { Component } from 'react';
import InputField from '../../elements/InputField';
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {}
        }
    }

    componentWillReceiveProps = (nextProps)=>{
        this.setState({errors: nextProps.errors});
    }
    
    submitResetForm = (event) =>{
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }
    }

    handleChange = event =>{
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    showLoginForm = event => {
        let showingSection = event.currentTarget.dataset.key;
        this.setState({
            displayForm: showingSection
        })
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="item active">
                <div className="sm">
                    <div id="fedupDix" className="row os-animation animated fadeInUp" data-os-animation="fadeInUp" data-os-animation-delay="0.50s">
                        <div className="col-md-12" id="modified_design">
                            <div className="signUp-container text-left">
                                <h1 className="login-h1">Reset Password</h1>
                                <p className="login-p">Create your new password. Password must be 8 – 50 characters with at least one upper and lower case letter, and one number</p>
                                    <form className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s" method="post" id="loginform" onSubmit={this.submitResetForm}>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "new_password",
                                                        type: "password",
                                                        name: "new_password",
                                                        label:"Enter New Password",
                                                        dataerror: errors.new_password,
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                            <div className="col-sm-12">
                                                <InputField
                                                    inputProps={{
                                                        id: "re_new_password",
                                                        type: "password",
                                                        name: "re_new_password",
                                                        label:"Re-enter New Password",
                                                        dataerror: errors.re_new_password
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <span id="invalidLogin" className="invalid-login"></span>
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-lg btn-block" id="sub-login" name="email_login" type="submit" onClick={this.submitResetForm}>Reset Password</button>
                                        </div>
                                    </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;