import MasterService from './MasterService';

const InventoryService = {
    ...MasterService,
    
    getCarDetail(postdata) {
        return this.post('inventory/inventory/usedstockcardetails', postdata);
    },

    addStock(postdata) {
        return this.post('inventory/inventory/saveusedstockcar', postdata);
    },

    updateStock(postdata) {
        return this.post('inventory/inventory/updateusedstockcar', postdata);
    },

    uploadFilesToS3(postdata, headers) {
        return this.post('core/commonservice/docs_upload', postdata, headers);
    },

    saveUsedCarImages(postdata, headers) {
        return this.post('inventory/inventory/uploadstockusedcarimage', postdata, headers);
    },

    deleteAllUsedCarImages(postdata, headers) {
        return this.post('inventory/inventory/delete_all_images', postdata, headers);
    },
    
    cityList(postdata) {
        return this.get('core/commonservice/state_city_all');
    },
    localityList(){
        return this.post('core/commonservice/locality_list',{});
    },

    colorList(postdata) {
        return this.get('core/commonservice/color_list');
    },

    deleteReason(postdata){
        return this.post('inventory/inventory/removeusedstockcar', postdata);        
    },

    stockStatusCount(postdata){       
        return this.post('inventory/inventory/countusedcarstatus', postdata);
    },
    onRoadCarPriceList(postdata){       
        return this.post('inventory/inventory/on_road_car_price', postdata);
    },
    getBlockMobile(postdata) {
        return this.post('inventory/inventory/blocked_mobiles', postdata);
    },
    getBlockMobileById(postdata) {
        return this.post('inventory/inventory/blocked_mobiles', postdata);
    },
    addBlockMobile(postdata) {
        return this.post('inventory/inventory/add_block_mobile', postdata);        
    },
    changeBlockedMobileStatus(postdata) {
        return this.post('inventory/inventory/change_block_mobile_status', postdata); 
    }
}

export default InventoryService;
